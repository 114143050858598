// SPDX-FileCopyrightText: 2022 Christian Meeßen (GFZ) <christian.meessen@gfz-potsdam.de>
// SPDX-FileCopyrightText: 2022 Helmholtz Centre Potsdam - GFZ German Research Centre for Geosciences
// SPDX-FileCopyrightText: 2022 Marc Hanisch (GFZ) <marc.hanisch@gfz-potsdam.de>
//
// SPDX-License-Identifier: EUPL-1.2

import LatestSpotlight, {SpotlightDescription} from '~/components/home/helmholtz/LatestSpotlight'
import ContentContainer from '~/components/layout/ContentContainer'
import HgfButton, {HgfButtonVariant} from './ui/HgfButton'

export default function SpotlightSection({spotlight}:{spotlight?: SpotlightDescription}) {

  if (spotlight === undefined) {
    return <></>
  }

  return (
    <div className='bg-[#ecfbfd] w-full'>
      <div className='container mx-auto p-6 md:p-10 xl:py-10 max-w-screen-xl text-secondary'>
        <h2 className='text-5xl'>Software Spotlights</h2>
        <div className='text-2xl mt-2'>The latest outstanding software product developed in Helmholtz.</div>
        <LatestSpotlight spotlight={spotlight}/>
        <div className='flex justify-end'>
          <HgfButton
            variant={HgfButtonVariant.DarkBlue}
            href='/spotlights?order=position'
            text='Browse all Spotlights'
          />
        </div>
      </div>
    </div>
  )
}
