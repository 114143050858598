import Image from 'next/image'
import HgfButton, {HgfButtonVariant} from './ui/HgfButton'
import {MouseEventHandler} from 'react'

export default function AddYourSoftwareSection({handleClickAddSoftware}:{handleClickAddSoftware: MouseEventHandler}) {
  return (
    <div className="container mx-auto p-6 md:p-10 xl:py-10 max-w-screen-xl text-secondary">
      <h2 className="text-5xl mb-4">Add your Research Software</h2>
      <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-20'>
        <div className="md:block overflow-clip relative h-full">
          <Image
            layout='fill'
            className="object-cover"
            alt="Someone typing on a laptop"
            src="/images/pexels-isaque-pereira-394377.jpg"
          />
        </div>
        <div>
          <div className='text-2xl mt-2'>
            The Helmholtz RSD is accessible to <span className="hgf-text-highlight">all users within the Helmholtz Association</span>.
            If you have an account at a Helmholtz institution, login and promote your Research Software now:
          </div>
          <HgfButton
            variant={HgfButtonVariant.MintGreen}
            onClick={handleClickAddSoftware}
            text='Add your software'
            className='mt-10'
          />
        </div>
      </div>
    </div>
  )
}
