// SPDX-FileCopyrightText: 2024 Dusan Mijatovic (Netherlands eScience Center)
// SPDX-FileCopyrightText: 2024 Netherlands eScience Center
//
// SPDX-License-Identifier: Apache-2.0

import {MouseEventHandler} from 'react'

export enum HgfButtonVariant {
  MintGreen,
  DarkBlue,
  White
}

export type HgfButtonProps = {
  text: string,
  href?: string,
  target?: string,
  width?: string,
  variant?: HgfButtonVariant,
  className?: string,
  onClick?: MouseEventHandler
}

export default function HgfButton({
  text,
  href,
  variant = HgfButtonVariant.MintGreen,
  target = '_self',
  width = '250px',
  className = '',
  onClick
}: HgfButtonProps) {

  let textColor : string = 'text-secondary'
  let bgColor: string = 'bg-[#05e5ba]'
  let hoverBgColor: string = 'hover:bg-primary'
  let hoverTextColor : string = 'hover:text-secondary'
  let borderProps : string = ''
  let hoverBorderProps : string = ''

  switch (variant) {
    case HgfButtonVariant.MintGreen:
      bgColor = 'bg-[#05e5ba]'
      textColor = 'text-secondary'
      hoverBgColor = 'hover:bg-primary'
      hoverTextColor = 'hover:text-secondary'
      break
    case HgfButtonVariant.DarkBlue:
      bgColor = 'bg-secondary'
      textColor = 'text-white'
      hoverBgColor = 'hover:bg-primary'
      hoverTextColor = 'hover:text-secondary'
      break
    case HgfButtonVariant.White:
      bgColor = 'bg-white'
      textColor = 'text-secondary'
      hoverBgColor = 'hover:bg-primary'
      borderProps = 'border-solid border-2 border-secondary'
      hoverBorderProps = 'hover:border-primary'
      break
    default:
      break
  }

  return <a
    onClick={onClick} href={href} target={target}
  >
    <div
      className={`mx-auto w-[${width}] ${bgColor} ${textColor} ${hoverBgColor} ${hoverTextColor} ${borderProps} ${hoverBorderProps} text-center font-medium text-2xl py-4 px-6 rounded-full ${className ?? ''}`}
    >
      {text}
    </div>
  </a>
}
