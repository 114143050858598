import LogoHelmholtz from '~/assets/logos/LogoHelmholtz.svg'
import HgfButton from './ui/HgfButton'

export default function ClaimSection () {
  return (
    <div className="bg-secondary bg-landing-page mb-10">
      <div className="flex flex-col sm:flex-row flex-wrap container mx-auto px-6 md:px-10 xl:px-0 pt-16 pb-12 max-w-screen-xl text-white">
        <div className="min-w-min flex flex-col">
          <LogoHelmholtz width="220" />
        </div>
        <div className="my-auto pt-4 md:pt-0 ml-auto">
          <h1 className="text-4xl md:text-5xl lg:text-6xl md:max-w-md lg:max-w-xl">Promote and Discover Research Software</h1>
          <div className="text-xl lg:text-2xl">Because software matters</div>
          <div className="flex pt-8">
            <HgfButton href='/software?order=mention_cnt' text='Browse software' className='mt-10'/>
          </div>
        </div>
      </div>
    </div>
  )
}
