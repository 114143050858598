import {OrganisationForOverview} from '~/types/Organisation'
import ParticipatingOrganisations from './ParticipatingOrganisations'
import {MutableRefObject} from 'react'

export default function ParticipatingOrganisationsSection(
  {organisations, simplebarRef, handleClickAddSoftware}:
  {organisations: OrganisationForOverview[], simplebarRef: MutableRefObject<undefined>, handleClickAddSoftware: React.MouseEventHandler}
) {
  if (organisations.length > 0) {
    return (
      <div
        className="container mx-auto p-6 md:p-10 xl:py-10 max-w-screen-xl text-secondary"
      >
        <div className="py-6">
          <h2 className="text-5xl pb-2">Contributions</h2>
          <div className='text-2xl'>We present software contributions by</div>
          <ParticipatingOrganisations organisations={organisations} sbRef={simplebarRef}/>
          <div className='text-xl pt-2'>Your organisation is not on the list? <a onClick={handleClickAddSoftware} className="underline">Add your software</a> now.</div>
        </div>
      </div>
    )
  }
}
