// SPDX-FileCopyrightText: 2024 Dusan Mijatovic (Netherlands eScience Center)
// SPDX-FileCopyrightText: 2024 Netherlands eScience Center
//
// SPDX-License-Identifier: Apache-2.0

import {NewsListItem} from '~/components/news/apiNews'
import HgfButton, {HgfButtonVariant} from '../ui/HgfButton'
import HgfNewsCard from './HgfNewsCard'


export default function HgfNewsSection({news}:{news?:NewsListItem[]}) {

  if (news != undefined && news?.length > 0){
    return (
      <div className='w-full'>
        <section
          id="latest-news"
          className="p-6 md:p-10 w-full max-w-screen-xl mx-auto">

          <h2 className="flex justify-start text-5xl text-secondary">
            Latest news
          </h2>

          <div
            className="grid gap-x-10 gap-y-10 grid-cols-1 md:grid-cols-2 md:gap-y-10 lg:grid-cols-3 lg:gap-y-0 pt-8"
          >
            {news.map(newsItem=>{
              return <HgfNewsCard key={`${newsItem.publication_date}`} newsItem={newsItem} />
            })}
            <HgfButton
              text="News archive"
              href="/news"
              target="_self"
              className='mt-10'
              variant={HgfButtonVariant.DarkBlue}
            />
          </div>
        </section>
      </div>
    )
  }
  return null
}
