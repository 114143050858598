// SPDX-FileCopyrightText: 2024 Dusan Mijatovic (Netherlands eScience Center)
// SPDX-FileCopyrightText: 2024 Netherlands eScience Center
//
// SPDX-License-Identifier: Apache-2.0

import Link from 'next/link'
import ImageWithPlaceholder from '~/components/layout/ImageWithPlaceholder'
import {NewsListItem, getCardImageUrl} from '~/components/news/apiNews'
import PublicationDate from '~/components/news/overview/card/PublicationDate'
import HgfNewsCardTextBody from './HgfNewsCardTextBody'

export default function HgfNewsCard({newsItem}:{newsItem:NewsListItem}) {
  const imgUrl = getCardImageUrl(newsItem.image_for_news)

  return (
    <div
      className='relative h-full'
    >
      <Link
        href={`/news/${newsItem.publication_date}/${newsItem.slug}`}
        className='h-full'
      >
        <div
          className="flex flex-col h-80 transition overflow-hidden bg-base-100 shadow-[rgba(0,0,0,.5)_0px_0px_10px] rounded-md"
        >
          {/* Cover image */}
          <div className="h-[65%] flex overflow-hidden relative bg-base-100">
            <ImageWithPlaceholder
              src={imgUrl}
              alt={`Image of article ${newsItem.title}`}
              type="gradient"
              className={'w-full text-base-content-disabled'}
              bgSize={'cover'}
            />
          </div>
          {/* Card body */}
          <div className="h-[35%] flex flex-col relative px-2">
            <PublicationDate
              publication_date={newsItem.publication_date}
              className='py-2 text-inherit'
            />
            <HgfNewsCardTextBody
              title={newsItem.title}
              summary={null}
            />
            {/* <div className="flex-1 flex flex-col justify-end">
              <NewsAuthors
                author={newsItem.author}
                className='pt-2'
              />
            </div> */}
          </div>
        </div>
      </Link>
    </div>
  )
}
