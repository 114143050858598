import ResearchFieldCollection from './ResearchFieldCollection'

const resetBackgroundImage = (event: React.MouseEvent<HTMLDivElement>) => {
  if (!(event.target instanceof HTMLDivElement)) return
  if (!(event.target.id === 'backgroundContainer')) return
  event.target.style.backgroundImage = 'url("/images/pexels-olena-bohovyk-3646172.jpg")'
}

const backgroundTransitionStyle = {
  'transition': 'background 0.3s ease 0.1s',
}

export default function ResearchFieldSection() {
  return (
    <div className="conainer mx-auto max-w-screen-xl text-white">
      <div className='lg:mx-10 bg-secondary'>
        <div
          id="backgroundContainer"
          className="w-full h-full p-12 bg-blend-multiply bg-center bg-cover bg-[#002864] bg-opacity-75 bg-[url(/images/pexels-olena-bohovyk-3646172.jpg)]"
          style={backgroundTransitionStyle}
          onMouseLeave={resetBackgroundImage}>
          <h2 className='text-5xl'>Discover software by Research Field</h2>
          <ResearchFieldCollection />
        </div>
      </div>
    </div>
  )
}
