// SPDX-FileCopyrightText: 2022 Christian Meeßen (GFZ) <christian.meessen@gfz-potsdam.de>
// SPDX-FileCopyrightText: 2022 Helmholtz Centre Potsdam - GFZ German Research Centre for Geosciences
// SPDX-FileCopyrightText: 2022 Marc Hanisch (GFZ) <marc.hanisch@gfz-potsdam.de>
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react'
import Link from 'next/link'
import 'simplebar/dist/simplebar.min.css'

/*! purgecss start ignore */
import {getImageUrl} from '~/utils/editImage'
/*! purgecss end ignore */


export type SpotlightDescription = {
  brand_name: string,
  short_statement: string,
  image_id: string,
  slug: string
}

export default function LatestSpotlight({spotlight}: {spotlight: SpotlightDescription}) {
  const {brand_name, image_id, short_statement, slug} = spotlight

  const imageUrl = getImageUrl(image_id) ?? ''
  const link = `/software/${slug}`
  const alt = `Logo of ${brand_name}`

  return(
    <Link
      href={link}
      passHref
    >
      <div
        className="w-full flex flex-row flex-wrap mt-8 hover:cursor-pointer group content-center"
      >
        <div className="h-[20rem] w-full md:w-1/2 content-center">
          <img
            alt={alt}
            className="group-hover:scale-105 h-full transition duration-50 object-contain mx-auto"
            src={imageUrl}
          />
        </div>
        <div className="md:w-1/2 md:pl-8 md:my-auto text-xl">
          <div className="text-4xl py-2">{brand_name}</div>
          <p>{short_statement}</p>
        </div>
      </div>
    </Link>
  )
}
